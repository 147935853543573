



















































































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Session from '@/models/graphql/Session';
import { format } from 'date-fns';
import { State } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import { Location } from 'vue-router';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  components: { FontAwesomeComponent, ButtonIconComponent, ButtonComponent },
})
export default class SessionCmsPageHeaderWidget extends VueBaseWidget {
  @Prop({ required: false, default: () => [] })
  private readonly data!: Session[];

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  @Prop({ required: false, default: 'center' })
  private readonly alignment!: string;

  @Prop({ required: false, default: false })
  private readonly editButton!: boolean;

  @Prop({ required: false, default: '' })
  private readonly btnRoute!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  private get ratingsData(): {icon: string; value: number}[] {
    return [
      {
        icon: 'heart',
        value: this.session?.loveCount || 0,
      },
      {
        icon: 'thumbs-up',
        value: this.session?.likeCount || 0,
      },
      {
        icon: 'thumbs-down',
        value: this.session?.dislikeCount || 0,
      },
    ];
  }

  private get session(): Session | undefined {
    if (this.data.length > 0 && this.data[0]) {
      return this.data[0];
    }
    return undefined;
  }

  private get handleBaseUrl(): string | null {
    if (this.route) {
      let r = this.route;
      const uid = WidgetHelper.entityCode(this.entityCode, this.$route as unknown as Location);
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${uid}`;
      }
      return r;
    }
    return null;
  }

  private get subtitle(): string {
    if (this.session
      && this.session.startTime
      && this.session.endTime) {
      const start = DateTimeHelper.utcToZonedTimeDate(
        `${this.session.startTime}Z`,
        this.selectedTzName,
      );
      const end = DateTimeHelper.utcToZonedTimeDate(
        `${this.session.endTime}Z`,
        this.selectedTzName,
      );
      const parts: string[] = [];
      parts.push(format(start, `${this.$t('app.date.dayOfWeekShort')}`, { locale: this.dateLocale }));
      parts.push(format(start, `${this.$t('app.date.monthDayShort')}`, { locale: this.dateLocale }));
      parts.push(`
      ${format(start, `${this.$t('app.date.year')}`, { locale: this.dateLocale })}
       ·
      ${format(start, `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale })}
      ${this.$t('app.date.dateTimeSeparator')}
      ${format(end, `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale })}
      `);
      parts.push(this.selectedTzAbbreviation);
      return parts.join(', ');
    }
    return '';
  }

  private handleEditUrl(): void {
    if (this.btnRoute) {
      let r = this.btnRoute;
      const uid = WidgetHelper.entityCode(this.entityCode, this.btnRoute as unknown as Location);
      const matches = this.btnRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        this.$router.push(r);
      }
      if (r[r.length - 1] === '/') {
        this.$router.push(`${r}${uid}`);
      }
      this.$router.push(r);
    }
  }
}
